<template>
  <div class="details-container">
    <v-row>
      <!-- Primeira Coluna: Informações Gerais -->
      <v-col cols="6">
        <h4>Detalhes do Item: {{ item.nota_fiscal }}</h4>
        <ul>
          <li><strong>Fornecedor:</strong> {{ item.fornecedor.nome_fantasia }}</li>
          <li><strong>Razão Social:</strong> {{ item.fornecedor.razao_social }}</li>
          <li><strong>Desconto:</strong> {{ item.desconto || 0 }}%</li>
          <li><strong>Data:</strong> {{ item.dataAquisicao | formatDatabr }}</li>
        </ul>
      </v-col>

      <!-- Segunda Coluna: Lista de Esterilizáveis e Equipamentos -->
      <v-col cols="6">
        <h4>Itens Associados</h4>

        <!-- Esterilizáveis -->
        <h5>Esterilizáveis</h5>
        <v-list>
          <template v-if="isLoading">
            <v-list-item>
              <v-list-item-content>
                <v-progress-circular indeterminate></v-progress-circular>
              </v-list-item-content>
            </v-list-item>
          </template>
          <template v-else>
            <v-list-item
              v-for="item in filteredEsterilizavel"
              :key="item.id"
            >
              <v-list-item-content>
                <span>ID: {{ item.id }} - Item: {{ item.nome.nome || 'Sem nome' }}</span>
              </v-list-item-content>
            </v-list-item>
          </template>
        </v-list>

        <!-- Equipamentos -->
        <h5>Equipamentos</h5>
        <v-list>
          <template v-if="isLoading">
            <v-list-item>
              <v-list-item-content>
                <v-progress-circular indeterminate></v-progress-circular>
              </v-list-item-content>
            </v-list-item>
          </template>
          <template v-else>
            <v-list-item
              v-for="item in filteredEquipamento"
              :key="item.id"
            >
              <v-list-item-content>
                <span>ID: {{ item.id }} - Item: {{ item.descricao.nome || 'Sem nome' }}</span>
              </v-list-item-content>
            </v-list-item>
          </template>
        </v-list>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import api from "@/http";

export default {
  props: ["item"],
  data() {
    return {
      esterelizavel: [],
      equipamento: [],
      isLoading: false,
    };
  },
  computed: {
    // Filtra esterilizáveis que possuem o mesmo ID de aquisição
    filteredEsterilizavel() {
      return this.esterelizavel.filter(
        (e) => e.aquisicao?.id === this.item.id
      );
    },
    // Filtra equipamentos que possuem o mesmo ID de aquisição
    filteredEquipamento() {
      return this.equipamento.filter(
        (e) => e.aquisicao?.id === this.item.id
      );
    },
  },
  methods: {
    async fetchData() {
      this.isLoading = true;
      try {
        const [esterilizavelResponse, equipamentoResponse] = await Promise.all([
          api.get("equipamentos/esterelizavels/"),
          api.get("equipamentos/equipamentos/"),
        ]);
        this.esterelizavel = esterilizavelResponse.data;
        this.equipamento = equipamentoResponse.data;
      } catch (error) {
        console.error("Erro ao buscar dados:", error);
      } finally {
        this.isLoading = false;
      }
    },
  },
  mounted() {
    this.fetchData();
  },
};
</script>

<style scoped>
.details-container {
  padding: 20px;
}
h4 {
  margin-bottom: 15px;
}
h5 {
  margin-top: 20px;
  margin-bottom: 10px;
}
</style>
